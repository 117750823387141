/* Container Styling */
.slider-container {
  width: 100%;
  height: 120px; /* Fixed height for the container */
  overflow: hidden; /* Hide content overflowing the container */
  position: relative;
  background-color: white; /* White background */
  padding: 10px 0;
}

/* Slider Styling */
.slider {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  animation: scroll 10s linear infinite;
}

/* Track Styling */
.slide-track {
  display: flex;
  gap: 16px; /* Space between icons */
}

/* Individual Slide Styling */
.slide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px; /* Fixed width for each icon */
  height: 100px; /* Fixed height for each icon */
  background: white; /* Optional background for each icon */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  overflow: hidden; /* Ensures content stays within bounds */
}

/* Image Styling */
.slide img {
  width: 80px; /* Fit within the slide */
  height: 80px;
  object-fit: contain; /* Maintain aspect ratio */
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.2)); /* Enhance icon sharpness */
}

/* Animation */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}
